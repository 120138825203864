@import '@wfp/styles/styles.css';
@import './style/search.css';
@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'Open Sans';
  src: url('../public/fonts/Open_Sans/static/OpenSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../public/fonts/Open_Sans/static/OpenSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}


body {
  font-family: 'Open Sans', sans-serif;
  background: var(--text-secondary, 'neutral-neutral95');
}
